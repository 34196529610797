import { z } from 'zod'
import { zStringNumber } from './util'

const destination = z.enum([ 'PDX80', 'PDX90', 'Other' ])
export const destinations = destination._def.values

const metadata = z.object({
  destination
})

const item = z.object({
  id: z.string().uuid(),
  name: z.string().min(1),
  model: z.string().min(1),
  software: z.string().min(1),
  ipn: z.string().min(1),
  quantity: zStringNumber(z.number().int().min(1)),
  metadata: metadata.optional()
})

const items = z.array(item).min(1)

const defaults = {
  metadata,
  item,
  items
}

export type CustomMetadata = z.infer<typeof metadata>
export type CustomItem = z.infer<typeof item>
export type CustomItems = z.infer<typeof items>

export const BlankItem: CustomItem = {
  id: '',
  name: '',
  model: '',
  software: '',
  ipn: '',
  quantity: 0,
  metadata: {
    destination: 'Other'
  }
}

export default defaults
