/* eslint-disable no-restricted-globals */
import React from 'react'
import _ from 'lodash'
import Shell from '../components/layout/Shell'
import Header from '../components/layout/Header'
import CartStore from '../stores/Cart'
import styles from '../styles/product.module.scss'
import getParam from '../utils/getParam'
import getQuery from '../utils/getQuery'
import { getApi } from '../modules/api'
import ProductBox from '../components/misc/ProductBox'
import ImageBox from '../components/misc/ImageBox'
import { sortItems } from '../utils/sort'
import { addToast } from '../utils/toasts'
import converter from 'number-to-words'

interface Props {
  product: dtype.Product
  items: dtype.Item[]
}

interface State {
  selected: number|null
  quantity: string
  addState: number
}

export default class ProductPage extends React.Component<Props, State> {
  public static routePath: string = '/product/:productId'

  state: State = {
    selected: null,
    quantity: '1',
    addState: 0
  }

  public static async preloadProps(match: any): Promise<any> {
    const id = getParam({ match }, 'productId')
    const {
      product,
      items
    }: Props = await getApi('/GetProduct', { id })

    items.sort(sortItems)

    return {
      product,
      items
    }
  }

  public componentDidMount() {
    const selected = getQuery(location.search, 'selected')

    if (selected !== null) {
      this.setState({ selected: parseInt(selected) })
    }
  }

  public render(): JSX.Element {
    const { product, items } = this.props

    return (
      <Shell>
        <div className={styles.shell}>
          <Header title={this.getProductName(product)} subtitle='Product' />
          <div className={styles.container}>
            <div className={styles.left}>
              <ImageBox url={product.image_url} className={styles.image} />
            </div>
            <div className={styles.right}>
              {items.map(this.renderItem.bind(this))}
              <div className={styles.actions}>
                {this.renderActions()}
              </div>
            </div>
          </div>
        </div>
      </Shell>
    )
  }

  private renderItem(item: any): JSX.Element {
    const { id, name, description } = item
    const { selected } = this.state
    const active = selected === id

    const toggle = () => selected === id
      ? this.setState({ selected: null})
      : this.setState({ selected: id })

    return (
      <ProductBox
        title={name}
        subtitle={description}
        onClick={toggle}
        active={active}
        key={id}
      />
    )
  }

  private renderActions(): JSX.Element {
    const { selected, quantity, addState } = this.state
    const disabled = selected === null || addState !== 0

    const addToCart = (): void => {
      if (selected === null) {
        return
      }
      
      CartStore.add(
        String(selected),
        Number(quantity)
      )

      this.setState({ addState: 1 })

      // Wow, this is a mess
      const name = this.getItemNameById(String(selected))
      const qty = converter.toWords(Number(quantity))

      addToast(`Added ${qty} ${name} to quote.`)

      setTimeout(() => {
        this.setState({ selected: null, quantity: '1', addState: 0 })
      }, 800)
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      this.setState({ quantity: event.target.value })
    }

    const onFocus = (): void => {
      this.setState({ quantity: '' })
    }

    const onBlur = (): void => {
      if (quantity === '') {
        this.setState({ quantity: '1' })
      }
    }

    const renderButtonText = (): string => {
      switch (addState) {
        case 1: return 'Added!'
        default: return 'Add to quote'
      }
    }

    return (
      <React.Fragment>
        <input
          type='text'
          pattern='\d*'
          value={disabled ? '0': quantity}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className='px-2'
        />
        <button
          onClick={addToCart}
          disabled={disabled}
        >{renderButtonText()}</button>
      </React.Fragment>
    )
  }

  private getProductName(product: any): string {
    return _.compact([
      product.make,
      product.model,
      product.variant
    ]).join(' ')
  }

  private getItemNameById(itemId: string): string {
    const { items } = this.props
    for (const item of items) {
      if (Number(item.id) === Number(itemId)) return item.name
    }

    return ''
  }
}
