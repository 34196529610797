export function withNamespace(namespace?: string|null) {
  return (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName
  }
}

/**
 * namespaces simply returns an object that, for any key accessed, returns the
 * string version of the key
 */
 interface Namespace {
  [key: string]: string
}

const handler: ProxyHandler<Namespace> = {
  get: (target: Namespace, prop: string, recv: any): string => {
    return String(prop)
  }
}

const blank: Namespace = {}

export const namespaces = new Proxy(blank, handler)
