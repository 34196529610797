import config from '../config'
import queryString from 'query-string'
import _ from 'lodash'

// Thanks:
// https://github.com/github/fetch/issues/89#issuecomment-256610849
function futch(url: string, opts?: any, onProgress?: any): Promise<Response> {
  opts = opts || {}

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(opts.method || 'get', url)

    for (var k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k])
    }

    xhr.onload = (evt: any) => resolve({
      status: evt.target.status,
      json: async () => JSON.parse(evt.target.responseText)
    } as any)
    
    xhr.onerror = reject

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = (evt: ProgressEvent) => {
        onProgress(Math.round(evt.loaded / evt.total * 100))
      }
    }

    xhr.send(opts.body)
  })
}

function generateApiUrl(relativePath: string): string {
  if (relativePath.indexOf('/') !== 0) {
    // Our relative path must always start with a leading /
    throw Error('path must start with a leading /')
  }

  return `${config.api.path}${relativePath}`
}

function getApiKey(): string {
  const savedKey = localStorage.getItem('access_code')

  return savedKey
    ? savedKey
    : 'anonymous'
}

async function handleResponse(response: Response|any): Promise<any> {
  const NOT_ALLOWED: number[] = [ 401, 403 ]
  const SUCCESS = 200

  if (NOT_ALLOWED.includes(response.status)) {
    window.location.href = '/login'
    return
  }

  if (response.status !== SUCCESS) {
    const err = Error(response.statusText) as any
    err.code = response.status

    throw err
  }

  const json = await response.json()
  return json
}

function generateHeaders(): Record<string, string> {
  const apiKey = getApiKey()

  return {
    'Authorization': `Bearer ${apiKey}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}

export async function getApi(path: string, query?: any): Promise<any> {
  const headers = generateHeaders()
  const absolutePath: string = generateApiUrl(path)

  let qs: string = ''

  if (query) {
    qs = '?' + queryString.stringify(query)
  }

  const response = await fetch(absolutePath + qs, {
    headers
  })
  
  return await handleResponse(response)
}

export async function postApi(path: string, body: any): Promise<any> {
  const headers = generateHeaders()
  const absolutePath: string = generateApiUrl(path)
  const response = await fetch(absolutePath, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  })

  return await handleResponse(response)
}

export async function uploadApi(path: string, file: any, onProgress?: any): Promise<any> {
  const form = new FormData()
  const headers = _.pick(generateHeaders(), [ 'Authorization' ])
  const absolutePath: string = generateApiUrl(path)

  form.append('file', file)

  const response = await futch(absolutePath, {
    method: 'POST',
    headers,
    body: form
  }, onProgress)

  return await handleResponse(response)
}