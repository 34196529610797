/* eslint-disable no-restricted-globals */
import React from 'react'
import AuthShell from '../components/layout/AuthShell'
import getParam from '../utils/getParam'
import styles from '../styles/login.module.scss'
import { BarLoader } from 'react-spinners'
import History from '../modules/history'

export default class AccessCodePage extends React.Component {
  public static routePath: string = '/access_code/:code'
  
  public static async preloadProps(match: any): Promise<any> {
    const code = getParam({ match }, 'code')
    localStorage.setItem('access_code', String(code))
  }

  public componentDidMount(): void {
    History.replace('/')
  }

  public render(): JSX.Element {
    return (
      <AuthShell>
        <div className={styles.auth}>
          <BarLoader color={'#000000'} />
        </div>
      </AuthShell>
    )
  }
}
