import React from 'react'
import AuthShell from '../components/layout/AuthShell'
import styles from '../styles/login.module.scss'
import { postApi } from '../modules/api'
import NProgress from 'nprogress'

interface State {
  email: string
  done: boolean
  loading: boolean
  error: boolean
}

export default class LoginPage extends React.Component<any, State> {
  public static routePath: string = '/login'
  public static routeExact: boolean = true

  state: State = {
    email: '',
    done: false,
    loading: false,
    error: false
  }

  public render(): JSX.Element {
    const { done } = this.state

    return (
      <AuthShell>
      {
        done
        ? this.renderDone()
        : this.renderLogin()
      }
      </AuthShell>
    )
  }

  private renderLogin(): JSX.Element {
    const { email, loading, error } = this.state
    const disabled = email === '' || loading === true

    const onChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
      this.setState({ email: evt.target.value })
    }

    const renderError = (): JSX.Element => {
      if (error === false) return <React.Fragment />
      return (
        <p className={styles.error}>
          <strong>Hmm, that didn't work.</strong>&nbsp;
          If you're sure you entered a valid email address, please try again in
          a few minutes.
        </p>
      )
    }

    return (
      <div className={styles.auth}>
        {renderError()}
        <input
          type='text'
          placeholder='user@domain.tld'
          value={email}
          onChange={onChange}
        />
        <button disabled={disabled} onClick={this.submit.bind(this)}>
          Get access
        </button>
      </div>
    )
  }

  private renderDone(): JSX.Element {
    return (
      <div className={styles.auth}>
        <p>
          <strong>Thanks!</strong> If your domain is approved to use this portal,
          you will receive an email shortly with a link to receive access.
        </p>
        <p className='mt-3 text-gray-500'>
          You can close this window.
        </p>
      </div>
    )

  }

  private async submit(): Promise<void> {
    const { email } = this.state

    this.setState({
      error: false,
      loading: true
    })
    
    NProgress.start()

    try {
      await postApi('/GetAccessCode', { email })
    } catch (e) {
      this.setState({
        error: true,
        loading: false
      })
      
      NProgress.done()
      return
    }

    this.setState({
      loading: false,
      done: true
    })

    NProgress.done()
  }
}
