import React from 'react'
import { Link } from 'react-router-dom'
import styles from './ProductBox.module.scss'

interface Props {
  link?: string
  title: string
  image?: string
  subtitle?: string
  onClick?: any
  active?: boolean
}

const ProductBox: React.FC<Props> = props => {
  const { link, onClick, active } = props

  const renderText = (): JSX.Element => {
    const { title, subtitle } = props

    return (
      <div className={styles.center}>
        <h3>{title}</h3>
        {
          subtitle
          ? <span>{subtitle}</span>
          : ''
        }
      </div>
    )
  }

  const renderImage = (): JSX.Element => {
    const { image = null } = props

    if (image === null) {
      return <React.Fragment />
    }

    const imgStyles = {
      backgroundImage: `url(${image})`
    }

    return (
      <div className={styles.left}>
        <div className={styles.image}>
          <div style={imgStyles} />
        </div>
      </div>
    )
  }

  const doClick = onClick
    ? onClick
    : () => {}

  const body = (
    <React.Fragment>
      {renderImage()}
      {renderText()}
    </React.Fragment>
  )

  const classNames = [
    styles.item,
    active ? styles.active : null
  ]
    .filter(i => !!i)
    .join(' ')

  return link
    ? <Link to={link} className={classNames}>
        {body}
      </Link>
    : <div className={classNames} onClick={doClick}>
        {body}
      </div>
}

export default ProductBox
