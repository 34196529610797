import { z } from 'zod'

export function zStringNumber(numberSchema: z.ZodNumber = z.number()) {
  const refiner = (value: string) => !isNaN(Number(value))

  const transformed = z
    .string()
    .min(1)
    .refine(refiner, { message: 'Must be a number.' })
    .transform((value: string): number => Number(value))
    .refine(data => numberSchema.safeParse(data).success)

  return transformed
}
