import React from 'react'
import Shell from '../components/layout/Shell'
import styles from '../styles/products.module.scss'
import { getApi, postApi } from '../modules/api'
import { BarLoader } from 'react-spinners'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Header from '../components/layout/Header'
import ProductBox from '../components/misc/ProductBox';
import { sortProducts, sortItems } from '../utils/sort'

interface State {
  query: string
  items: dtype.Item[]|null
  searching: boolean
}

interface Props {
  products: dtype.Product[]
}

export default class ProductsPage extends React.Component<Props, State> {
  public static routePath: string = '/'
  public static routeExact: boolean = true

  private _search: any

  state: State = {
    query: '',
    items: null,
    searching: false
  }

  constructor(props: Props) {
    super(props)
    this._search = _.debounce(this.search.bind(this), 300)
  }

  public static async preloadProps(): Promise<Props> {
    const { products }: Props = await getApi('/GetProducts')

    products.sort(sortProducts)

    return { products }
  }

  public render(): JSX.Element {
    const {
      query,
      items,
      searching
    } = this.state

    const renderScreen = (): JSX.Element => {
      if (searching) {
        // We are currently searching
        return this.renderSearching()
      }
  
      if (items !== null) {
        if (query !== '' && items.length === 0) {
          return this.renderEmpty()
        }
  
        if (query !== '' && items.length > 0) {
          return this.renderSearchResults()
        }
      }

      return this.renderProducts()
    }

    return (
      <Shell>
        <div className={styles.shell}>
          <Header
            subtitle='Browse'
            title='Inventory'
            className={styles.header}
          />

          <div className={styles.search}>
            <input
              type='text'
              placeholder='Start typing to search...'
              onChange={this.onChange.bind(this)}
              value={query}
            />
            {
              searching
              ? <div className={styles.loader}>
                  <BarLoader
                    height={'3px'}
                    width={'40px'}
                  />
                </div>
              : null
            }
          </div>
          {renderScreen()} 
        </div>
      </Shell>
    )
  }

  private renderSearching(): JSX.Element {
    return (
      <div>
      </div>
    )
  }

  private renderEmpty(): JSX.Element {
    return (
      <span className={styles.empty}>
        <strong>Sorry, no results.</strong> Would you like to <Link to='/request'>request an item?</Link>
      </span>
    )
  }

  private renderSearchResults(): JSX.Element {
    const { items } = this.state

    if (items === null) {
      // this is an no-op
      return <React.Fragment />
    }

    return (
      <div className={styles.items}>
        {items.map(this.renderSearchItem.bind(this))}
      </div>
    )
  }

  private renderProducts(): JSX.Element {
    const { products } = this.props

    return (
      <div className={styles.products}>
        {products.map(this.renderProduct.bind(this))}
      </div>
    )
  }

  private renderProduct(product: any): JSX.Element {
    const { id, make, model, variant, image_url } = product
    const imgStyles = {
      backgroundImage: `url(${image_url})`
    }

    return (
      <Link to={`/product/${id}`} key={id} className={styles.product}>
        <div className={styles.image}>
          <div style={imgStyles} />
        </div>
  
        <div className={styles.desc}>
          <h5>{make}</h5>
          <h4>{model} {variant}</h4>
        </div>
      </Link>
    )
  }

  private renderSearchItem(item: any): JSX.Element {
    const { id, product, name, image, description } = item
    const link: string = `/product/${product}?selected=${id}`

    return (
      <ProductBox
        key={id}
        image={image}
        link={link}
        title={name}
        subtitle={description}
      />
    )
  }

  private onChange(evt: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ query: evt.target.value, searching: true })
    this._search()
  }

  private async search(): Promise<void> {
    const { query } = this.state

    if (query.length < 1) {
      this.setState({ items: null, searching: false })
      return
    }

    try {
      const {
        results
      }: {
        results: dtype.Item[]
      } = await postApi('/Search', { query })

      results.sort(sortItems)

      this.setState({
        items: results,
        searching: false
      })
    } catch (e) {
      this.setState({
        items: null,
        searching: false
      })
    }
  }
}
