export function sortProducts(a: dtype.Product, b: dtype.Product): number {
  const aName: string = [ a.make, a.model, a.variant ].join(' ').toLowerCase()
  const bName: string = [ b.make, b.model, b.variant ].join(' ').toLowerCase()

  return aName.localeCompare(bName)
}

export function sortItems(a: dtype.Item, b: dtype.Item): number {
  const aName: string = [ a.name, a.description ].join(' ').toLowerCase()
  const bName: string = [ b.name, b.description ].join(' ').toLowerCase()

  return aName.localeCompare(bName)
}