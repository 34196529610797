const api = {
  path: 'https://deletype-procurement.azurewebsites.net/api'
}

const isDev = window.location.host === 'localhost:3000'

if (isDev) {
  api.path = 'http://localhost:7071/api'
}

const config = {
  api
}

export default config
