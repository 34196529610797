export default function stateThenProps<T>(state: any, props: T): T {
  const newObject = { ...props } as any
  
  for (const key in state) {
    if (!state.hasOwnProperty(key)) continue
    if (state[key] === null) continue
    newObject[key] = state[key]
  }

  return newObject as T
}
