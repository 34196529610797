import React from 'react'

interface Props {
  title?: string
  subtitle?: string
  className?: string
}

const Header: React.FC<Props> = props => {
  const {
    title,
    subtitle,
    className = ''
  } = props

  return (
    <div className={`mb-6 ${className}`}>
      {
        subtitle
         ? <h3 className='text-xs font-normal text-gray-400'>{subtitle}</h3>
         : ''
      }
      {
        title
        ? <h2 className='text-lg font-medium text-black'>{title}</h2>
        : ''
      }
    </div>
  )
}

export default Header
