import React from 'react'
import Shell from '../../components/layout/Shell'
import styles from '../../styles/admin.module.scss'
import Header from '../../components/layout/Header'
import { getApi } from '../../modules/api'
import ProductBox from '../../components/misc/ProductBox'
import { sortProducts } from '../../utils/sort'
import { Link } from 'react-router-dom'

interface Props {
  products: dtype.Product[]
}

export default class ListProductsPage extends React.Component<Props> {
  public static routePath: string = '/admin/products'

  public static async preloadProps(): Promise<Props> {
    const { products }: Props = await getApi('/GetProducts')

    products.sort(sortProducts)

    return { products }
  }
  
  public render(): JSX.Element {
    const { products } = this.props

    return (
      <Shell>
        <div className={styles.shell}>
          <Header
            subtitle='Admin'
            title='All products'
          />

          {products.map(this.renderProduct.bind(this))}
          <Link to='/admin/product/new' className={styles.button}>
            Create product
          </Link>
        </div>
      </Shell>
    )
  }

  private renderProduct(product: dtype.Product): JSX.Element {
    const { id, make, model, variant } = product
    const name: string = [ make, model, variant ].join(' ')
    const link: string = `/admin/product/edit/${id}`

    return (
      <React.Fragment key={id}>
        <ProductBox
          title={name}
          link={link}
        />
      </React.Fragment>
    )
  }
}
