import React from 'react'
import Shell from '../../components/layout/Shell'
import styles from '../../styles/admin/edit_product.module.scss'
import Header from '../../components/layout/Header'
import getParam from '../../utils/getParam'
import { getApi, postApi, uploadApi } from '../../modules/api'
import ImageBox from '../../components/misc/ImageBox'
import ProductBox from '../../components/misc/ProductBox'
import { sortItems } from '../../utils/sort'
import { addToast } from '../../utils/toasts'
import NProgress from 'nprogress'
import history from '../../modules/history'
import stateThenProps from '../../utils/stateThenProps'
import _ from 'lodash'
import { Link } from 'react-router-dom'

interface Props {
  product: dtype.Product
  items: dtype.ExtendedItem[]
  id: string
}

interface State {
  product: dtype.Product
  loading: boolean
}

const EMPTY_PRODUCT: dtype.Product = {
  id: '_new',
  make: '',
  model: '',
  variant: '',
  image_url: ''
}

export default class EditProductPage extends React.Component<Props, State> {
  private uploadRef = React.createRef<HTMLInputElement>()

  public static routePath: string[] = [
    '/admin/product/edit/:productId',
    '/admin/product/new'
  ]

  state: State = {
    product: { ...EMPTY_PRODUCT },
    loading: false
  }

  public static async preloadProps(match: any): Promise<Props> {
    const id = getParam({ match }, 'productId')

    if (id === null) {
      // We're creating a new product
      return {
        product: { ...EMPTY_PRODUCT },
        items: [],
        id: '_new'
      }
    }

    const { product, items }: Props = await getApi('/GetProduct', { id })

    items.sort(sortItems)

    return {
      product,
      items,
      id: String(id)
    }
  }

  public componentDidMount(): void {
    const product = { ...this.props.product }
    this.setState({ product })
  }
  
  public render(): JSX.Element {
    const { product, items, id } = stateThenProps(this.state, this.props)
    const { image_url } = product

    const handleImageClick = () => {
      if (this.uploadRef.current) this.uploadRef.current.click()
    }

    return (
      <Shell>
        <div className={styles.shell}>
          <div className={styles.condense}>
            <Header subtitle='Product Metadata' className={styles.header} />
            <div className={styles.metadata}>
              <div className={styles.left}>
                <input
                  type='file'
                  ref={this.uploadRef}
                  onChange={this.onFileChange.bind(this)}
                />
                <ImageBox
                  url={image_url}
                  className={styles.image}
                  onClick={handleImageClick}
                />
              </div>
              <div className={styles.right}>
                {this.renderForm()}
              </div>
            </div>
            {
              id !== '_new'
              ? <div className={styles.items}>
                  <Header subtitle='Inventory Items' className={styles.header} />
                  {items.map(this.renderItem.bind(this))}
                  <Link to={`/admin/item/new/${product.id}`} className={styles.button}>
                    Create new item
                  </Link>
                </div>
              : ''
            }
            {
              id !== '_new'
              ? <div className={styles.actions}>
                  <button
                    className={`${styles.button} ${styles.danger}`}
                    onClick={this.remove.bind(this)}
                  >Delete Product</button>
                </div>
              : ''
            }
          </div>
        </div>
      </Shell>
    )
  }

  private async onFileChange(
    evt: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (evt.target.files === null || evt.target.files.length !== 1) {
      return
    }
    
    addToast('Uploading selected product image, please wait...', {
      appearance: 'info'
    })

    NProgress.start()

    const file = evt.target.files[0]

    let data = null

    try {
      data = await uploadApi('/UploadProductImage', file)
    } catch (e) {
      NProgress.done()
      addToast(`Hmm, that didn't work. Please try again in a bit.`, {
        appearance: 'error'
      })

      return
    }

    const src = 'https://deletypeprocurement.blob.core.windows.net/portal-assets/' + data.name
    const product = { ...this.state.product }
    
    product.image_url = src

    this.setState({ product })

    NProgress.done()
    addToast('Image uploaded successfully!', { appearance: 'success' })
  }

  private onChange(name: string): any {
    return (evt: React.ChangeEvent<HTMLInputElement>) => {
      const product = { ...this.state.product }
      product[name] = evt.target.value
      this.setState({ product })
    }
  }

  private renderItem(item: dtype.ExtendedItem): JSX.Element {
    const { product } = this.props
    const { id, name, description } = item

    const go = () => history.push(`/admin/item/edit/${id}`)

    return (
      <React.Fragment key={id}>
        <ProductBox
          title={name}
          subtitle={description}
          image={product.image_url}
          onClick={go}
        />
      </React.Fragment>
    )
  }

  private renderForm(): JSX.Element {
    const { loading } = this.state
    const { product, id } = stateThenProps(this.state, this.props)
    const cannotModify = _.isEqual(product, this.props.product)
    const isEnabled = (
      !loading &&
      !cannotModify &&
      product.make !== '' &&
      product.model !== '' &&
      product.variant !== '' &&
      product.image_url !== ''
    )

    const buttonText = (): string => {
      if (loading) return 'Hold on...'
      if (id === '_new') return 'Create'
      return 'Update'
    }

    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <div className={styles.group}>
          <input
            type='text'
            placeholder='Apple'
            value={product.make}
            onChange={this.onChange('make').bind(this)}
          />
        </div>

        <div className={styles.group}>
          <input
            type='text'
            placeholder='iPhone'
            value={product.model}
            onChange={this.onChange('model').bind(this)}
          />
        </div>

        <div className={styles.group}>
          <input
            type='text'
            placeholder='X'
            value={product.variant}
            onChange={this.onChange('variant').bind(this)}
          />
        </div>

        <button type='submit' disabled={!isEnabled}>
          {buttonText()}
        </button>
      </form>
    )
  }

  private async onSubmit(evt: React.FormEvent): Promise<void> {
    evt.preventDefault()

    this.setState({ loading: true })
    NProgress.start()

    const { id } = this.props
    const { product } = this.state
    const values = { ...product } as dtype.Product


    if (id === '_new') {
      return this.create(values)
    }

    return this.update(values)
  }

  private async update(values: dtype.Product): Promise<void> {
    const done = () => {
      this.setState({ loading: false })
      NProgress.done()
    }

    try {
      await postApi('/UpdateProduct', values)
    } catch (e) {
      addToast(`Hmm, that didn't work. Please check your data and try again.`, {
        appearance: 'error'
      })

      return done()
    }

    addToast('Product updated successfully.')
    return done()
  }

  private async create(values: dtype.Product): Promise<void> {
    const done = () => {
      this.setState({ loading: false })
      NProgress.done()
    }

    try {
      const data = await postApi('/CreateProduct', values)
      history.replace(`/admin/product/edit/${data.id}`)
    } catch (e) {
      addToast(`Hmm, that didn't work. Please check your data and try again.`, {
        appearance: 'error'
      })

      return done()
    }
  }

  private async remove(): Promise<void> {
    const { id } = this.props

    const confirm = window.confirm([
      'Are you sure you want to delete this product? All associated items will',
      'also be removed'
    ].join(' '))

    if (confirm === false) {
      return
    }

    NProgress.start()

    try {
      await postApi('/RemoveProduct', { id })
    } catch (e) {
      NProgress.done()

      addToast(`Hmm, that didn't work. Try again in a bit.`,
        { appearance: 'error' }
      )

      return
    }

    addToast('Product (and all associated items) removed.')
    history.replace('/admin/products')
  }
}
