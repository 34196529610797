import React from 'react'
import styles from './Shell.module.scss'
import Nav from './Nav'

interface Props {
  children: any
}

class Shell extends React.Component<Props> {
  public render(): JSX.Element {
    const { children } = this.props

    return (
      <div className={styles.app}>
        <Nav />
        <div className={styles.page}>
          {children}
        </div>
      </div>
    )
  }
}

export default Shell