import Shell from '../components/layout/Shell'
import styles from '../styles/request.module.scss'
import Header from '../components/layout/Header'
import createSchema from '../utils/createSchema'
import requestSchema, { CustomItem, BlankItem, destinations } from '../schemas/request'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { v4 as uuid } from 'uuid'
import { useState } from 'react'
import { withNamespace } from '../utils/namespaces'
import classNames from 'classnames'
import TextInput from '../components/forms/TextInput'
import { get } from 'lodash'
import Empty from '../components/misc/Empty'
import { FaGrinBeamSweat } from 'react-icons/fa'
import { z } from 'zod'
import Button from '../components/elements/Button'
import { postApi } from '../modules/api'
import nProgress from 'nprogress'
import history from '../modules/history'

const blankInitialValues = {
  metadata: {
    awsRef: '',
    notes: ''
  },
  items: [] as any[]
}

type InitialValues = typeof blankInitialValues

const schema = createSchema()
  .with('metadata', z.object({
    awsRef: z.string().min(1).max(100),
    notes: z.string().max(4000).optional()
  }))
  .with('items', requestSchema.items)

interface Props {
  initialValues: InitialValues
}

const STORAGE_KEY = 'dtype.proc.request.v1'

const RequestPage: dtype.RoutableFC<Props> = props => {
  const { initialValues } = props
  const [ editingId, setEditingId ] = useState<string>('')

  const ns = withNamespace('items')

  interface RenderRowOptions {
    row: CustomItem
    index: number
    formikProps: FormikProps<InitialValues>
  }

  const save = (data: InitialValues, newEditingId: string = ''): void => {
    localStorage.setItem('dtype.proc.request.v1', JSON.stringify(data))
    setEditingId(newEditingId)
  }

  const renderEditRow = ({ row, index, formikProps }: RenderRowOptions) => {
    const remove = (): void => {
      const currentValues = get(formikProps.values, 'items')
      const newValues = currentValues.filter(val => val.id !== row.id)
      const formData = {
        ...formikProps.values,
        items: newValues
      }

      formikProps.resetForm({ values: formData })
      save(formData)
    }

    const errorsThisRow = get(formikProps.errors, ns(String(index)))
    const hasErrors: boolean = !!errorsThisRow

    const removeButtonStyles = classNames({
      'text-sm font-medium': true,
      'text-red-600 hover:text-red-900': true
    })

    const saveButtonStyles = classNames({
      'text-sm font-medium': true,
      'text-blue-600 hover:text-blue-900': !hasErrors,
      'text-gray-500 cursor-not-allowed': hasErrors
    })

    return (
      <tr key={row.id}>
        <td className='whitespace-nowrap text-sm'>
          <TextInput
            autoFocus
            name={ns(`${index}.name`)}
            placeholder='Name'
            useInlineStyle
            inputClassNames='px-6 py-4 font-medium'
            onFocus={evt => { evt.target.select() }}
          />
        </td>
        <td className='whitespace-nowrap text-sm'>
          <TextInput
            name={ns(`${index}.model`)}
            placeholder='Model'
            useInlineStyle
            inputClassNames='px-6 py-4'
            onFocus={evt => { evt.target.select() }}
          />
        </td>
        <td className='whitespace-nowrap text-sm'>
          <TextInput
            name={ns(`${index}.software`)}
            placeholder='Software'
            useInlineStyle
            inputClassNames='px-6 py-4'
            onFocus={evt => { evt.target.select() }}
          />
        </td>
        <td className='whitespace-nowrap text-sm'>
          <TextInput
            name={ns(`${index}.ipn`)}
            placeholder='IPN'
            useInlineStyle
            inputClassNames='px-6 py-4'
            onFocus={evt => { evt.target.select() }}
          />
        </td>
        <td className='whitespace-nowrap text-sm'>
          <TextInput
            name={ns(`${index}.quantity`)}
            placeholder='QTY'
            className='w-32'
            useInlineStyle
            inputClassNames='px-6 py-4'
            onFocus={evt => { evt.target.select() }}
          />
        </td>
        <td className='whitespace-nowrap text-sm'>
          <select
            {...formikProps.getFieldProps(ns(`${index}.metadata.destination`))}
            className='block pl-5 pr-10 text-sm focus:outline-none focus:ring-2 border-0 w-32 m-0'
          >
            <option>Choose...</option>
            {
              destinations.map(dest => {
                return <option key={dest}>{dest}</option>
              })
            }
          </select>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-right'>
          <button
            onClick={remove}
            type='button'
            className={removeButtonStyles}
          >
            Remove
          </button>
          <span className='text-gray-300 mx-2'>|</span>
          <button
            onClick={() => save(formikProps.values)}
            type='button'
            disabled={hasErrors}
            className={saveButtonStyles}
          >
            Save
          </button>
        </td>
      </tr>
    )
  }
  
  const renderRegularRow = ({ row, index, formikProps }: RenderRowOptions): JSX.Element => {
    const isEditing = editingId !== ''
    const edit = (): void => {
      if (isEditing) return
      setEditingId(row.id)
    }

    const duplicate = (): void => {
      if (isEditing) return

      const rows = [ ...get(formikProps.values, 'items') ]
      const newRow = { ...row, id: uuid() }
      
      rows.splice(index + 1, 0, newRow)

      formikProps.setValues({
        ...formikProps.values,
        items: rows
      })

      setEditingId(newRow.id)
    }

    const buttonClasses = classNames({
      'text-sm font-medium': true,
      'text-blue-600 hover:text-blue-900': !isEditing,
      'text-gray-500 cursor-not-allowed': isEditing
    })

    const destination = row.metadata && row.metadata.destination
      ? row.metadata.destination
      : 'Other'

    return (
      <tr key={row.id}>
        <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>{row.name}</td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{row.model}</td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{row.software}</td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{row.ipn}</td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{row.quantity}</td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{destination}</td>
        <td className='px-6 py-4 whitespace-nowrap text-right'>
          <button type='button' onClick={duplicate} className={buttonClasses} disabled={isEditing}>
            Duplicate
          </button>
          <span className='text-gray-300 mx-2'>|</span>
          <button type='button' onClick={edit} className={buttonClasses} disabled={isEditing}>
            Edit
          </button>
        </td>
      </tr>
    )
  }
  

  const renderRow = (formikProps: FormikProps<InitialValues>) => {
    return (row: CustomItem, index: number): JSX.Element => {
      const params = { row, index, formikProps }

      return row.id === editingId
        ? renderEditRow(params)
        : renderRegularRow(params)
    }
  }

  const renderEmpty = (): JSX.Element => {
    return (
      <tr>
        <td colSpan={10} className='h-40'>
          <Empty
            icon={<FaGrinBeamSweat size={60} />}
            message={<span className='block mt-2'>No devices added yet.</span>}
          />
        </td>
      </tr>
    )
  }

  const renderTable = (formikProps: FormikProps<InitialValues>): JSX.Element => {
    const { items } = formikProps.values
    const actionsDisabled = formikProps.isSubmitting || items.length === 0

    const clearAll = (): void => {
      formikProps.resetForm({ values: blankInitialValues })
      localStorage.removeItem(STORAGE_KEY)
    }

    const addItem = (): void => {
      const id: string = uuid()
      const blank = {
        ...BlankItem,
        metadata: {
          selected: 'Choose...'
        },
        quantity: '0',
        id
      }

      const newItems = [ ...items, blank ]
      const priorValues = { ...formikProps.values }
      
      formikProps.setValues({
        ...priorValues,
        items: newItems
      })
      
      save(priorValues, id)
    }

    const isEditing: boolean = editingId !== ''
    const editingIndex: number = get(formikProps.values, 'items').findIndex(
      row => row.id === editingId
    )

    const editingIndexHasErrors = !!get(formikProps.errors, ns(String(editingIndex)))
    const addItemDisabled: boolean = isEditing && editingIndexHasErrors

    const thClasses: string = 'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
    const addItemButtonClasses = classNames({
      'block focus:outline-none min-w-full text-sm font-medium text-center px-4 py-3 sm:rounded-b-lg': true,
      'text-gray-500 cursor-not-allowed': addItemDisabled,
      'text-blue-600 hover:text-blue-900': !addItemDisabled
    })

    return (
      <div>
        <div className='flex flex-col'>
          <div className='-my-2'>
            <div className='py-2 align-middle inline-block min-w-full'>
              <div className='overflow-hidden border-b border-t border-gray-200'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th scope='col' className={thClasses}>Device</th>
                      <th scope='col' className={thClasses}>Model</th>
                      <th scope='col' className={thClasses}>Software</th>
                      <th scope='col' className={thClasses}>IPN</th>
                      <th scope='col' className={thClasses}>QTY</th>
                      <th scope='col' className={thClasses}>Dest</th>
                      <th scope='col' className='relative px-6 py-3'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {items.length === 0 ? renderEmpty() : null}
                    {items.map(renderRow(formikProps))}
                  </tbody>
                </table>
                <div className='border-t border-gray-200 bg-gray-50 sm:px-6'>
                  <button
                    onClick={addItem}
                    type='button'
                    disabled={addItemDisabled}
                    className={addItemButtonClasses}>
                    Add device
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className={`flex ${styles.shell}`} style={{ marginTop: '20px' }}>
          <div className='flex flex-1 justify-start items-baseline'>
            <button
              onClick={clearAll}
              type='button'
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >Clear all</button>
          </div>
          <div className=''>
            <div className=''>
              <textarea
                {...formikProps.getFieldProps('metadata.notes')}
                placeholder='Any notes for this request?'
                disabled={actionsDisabled}
                className={classNames({
                  'max-w-lgblock w-full sm:text-sm rounded-md mb-2': true,
                  'focus:ring-blue-500 focus:border-blue-500 border-gray-300 shadow-sm ': items.length > 0,
                  'border-gray-200 placeholder-gray-300 cursor-not-allowed': actionsDisabled
                })}
              >
      
              </textarea>
            </div>
            <div className='flex justify-end'>
              <TextInput
                name='metadata.awsRef'
                className='flex w-64 mr-2'
                placeholder='AWS reference'
                disabled={actionsDisabled}
              />

              <Button
                disabled={actionsDisabled}
                type='submit'
              >
              {
                formikProps.isSubmitting
                ? 'Requesting...'
                : 'Request quote'
              }
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const _onSubmit = async (values: any, frmk: FormikHelpers<any>) => {
    const done = () => {
      nProgress.done()
      frmk.setSubmitting(false)
    }

    frmk.setSubmitting(true)
    nProgress.start()

    try {
      await postApi('/RequestBuilderQuoteV1', values)
    } catch (e) {
      done()
      console.log('something went wrong:', e)
      return
    }

    done()
    localStorage.removeItem(STORAGE_KEY)
    history.push('/thanks')
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>) => {
    _onSubmit(values, frmk)
  }

  const renderForm = (formikProps: FormikProps<InitialValues>): JSX.Element => {
    return (
      <form onSubmit={formikProps.handleSubmit}>
        {renderTable(formikProps)}
      </form>
    )
  }

  return (
    <Shell>
      <div className={styles.shell}>
        <Header title='Requests' subtitle='Extended Inventory' />
      </div>
      <Formik
        initialValues={initialValues}
        validate={schema.validate}
        onSubmit={onSubmit}
      >{renderForm}</Formik>
    </Shell>
  )
}

RequestPage.routePaths = [
  { path: '/request', exact: true } 
]

RequestPage.preloadProps = async () => {
  const diskData = localStorage.getItem(STORAGE_KEY)

  if (diskData !== null) {
    let diskObj: any = {}

    try {
      diskObj = JSON.parse(diskData)
    } catch (e) {
      diskObj = {}

      // Clear disk data, load nothing, the JSON was invalid
      console.warn('JSON on disk was invalid, removing')
      localStorage.removeItem(STORAGE_KEY)
    }

    // Now we need to validate the schema
    const invalidFields = schema.getInvalidFields(diskObj)

    /**
     * Some fields need to be skipped for validation since they are allowed to
     * be in a different state for rendering
     */
    const skipFields = [
      'metadata.awsRef' // can be blank initially
    ]
    
    const invalidFieldsWithoutSkipped = invalidFields.filter(
      field => !skipFields.includes(field)
    )

    if (invalidFieldsWithoutSkipped.length > 0) {
      console.warn('Failed to validate data on disk:', invalidFields)
      localStorage.removeItem(STORAGE_KEY)
    }

    return { initialValues: diskObj }
  }

  return { initialValues: blankInitialValues }
}

export default RequestPage
