import React from 'react'
import { ToastProvider, withToastManager } from 'react-toast-notifications'
import { stashToastManager } from '../../utils/toasts'

/**
 * This is just a dummy element that gets mounted when the app renders so that
 * we may post toast notifications without decorating our entire application
 */
class ToastHandler extends React.Component<any> {
  constructor(props: any) {
    super(props)
    stashToastManager(this.props.toastManager)
  }

  public render(): JSX.Element {
    return <React.Fragment />
  }
}

const ToastHandlerWithToasts = withToastManager(ToastHandler)
const ToastRoot = (): JSX.Element => (
  <ToastProvider>
    <ToastHandlerWithToasts />
  </ToastProvider>
)

export default ToastRoot