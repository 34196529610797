import classNames from 'classnames'
import { omit } from 'lodash'
import React from 'react'

type ReactHTMLButton = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

interface Props extends ReactHTMLButton {
  size?: string
  color?: string
}

const defaultClasses = classNames(
  'focus:outline-none focus:ring-2 focus:ring-offset-2',
  'inline-flex items-center border border-transparent',
  'rounded shadow-sm text-white'
)

const disabledClasses = classNames(
  'cursor-not-allowed opacity-50'
)

const sizes = {
  'xs': classNames(
    'px-2.5 py-1.5 text-xs font-medium'
  )
}

const Button: React.FC<Props> = props => {
  const {
    size = 'xs',
    color = 'blue',
    disabled = false
  } = props

  const withoutCustomProps = omit(props, [
    'size',
    'color'
  ])

  const colorClasses = (color: string): string => {
    return classNames({
      [`bg-${color}-600  focus:ring-${color}-500`]: true,
      [`hover:bg-${color}-700`]: !disabled
    })
  }

  const classes = classNames({
    [defaultClasses]: true,
    [disabledClasses]: disabled,
    [sizes.xs]: size === 'xs',
    [colorClasses(color)]: true
  })

  return (
    <button {...withoutCustomProps} className={classes}>{props.children}</button>
  )
}

export default Button
