export default function getParam (props: any, paramName: string): null|string {
  if (
    typeof props === 'object' &&
    props.hasOwnProperty('match') &&
    typeof props.match === 'object' &&
    props.match.hasOwnProperty('params') &&
    typeof props.match.params === 'object' &&
    props.match.params.hasOwnProperty(paramName) &&
    typeof props.match.params[paramName] !== 'undefined'
  ) {
    return props.match.params[paramName]
  }

  return null
}
