import React from 'react'
import styles from './ImageBox.module.scss'

interface Props {
  url: string
  width?: string
  height?: string
  className?: string
  onClick?: any
}

const ImageBox: React.FC<Props> = props => {
  const { url, width, height, className, onClick } = props
  const canClick: boolean = !!onClick

  const imgStyles = {
    backgroundImage: `url(${url})`
  }

  const containerStyles: any = {}

  if (width) containerStyles.width = width
  if (height) containerStyles.height = height
  if (canClick) containerStyles.cursor = 'pointer'

  const classNames: string = [ styles.image, className ]
    .filter(i => !!i)
    .join(' ')

  const click = canClick ? onClick : () => {}

  return (
    <div className={classNames} style={containerStyles} onClick={click}>
      <div style={imgStyles} />
    </div>
  )
}

export default ImageBox
