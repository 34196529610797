/* eslint-disable no-restricted-globals */
import React, { useState } from 'react'
import styles from './Nav.module.scss'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import CartStore from '../../stores/Cart'
import { getApi } from '../../modules/api'
import {
  IoIosBuild,
  IoIosClipboard,
  IoIosExit,
  IoIosList,
  IoIosPricetags
} from 'react-icons/io'
import useAsyncEffect from 'use-async-effect'

let cachedGrants: any = null

const ICON_SIZE: number = 25 

const checkGrants = async () => {
  if (cachedGrants === null) {
    cachedGrants = await getApi('/CheckGrants')
  }
  
  return cachedGrants
}

const Nav: React.FC = props => {
  const [ grants, setGrants ] = useState<any>(null)
  
  useAsyncEffect(async isMounted => {
    const grants = await checkGrants()
    if (isMounted()) setGrants(grants)
  }, [])

  const signOut = (evt: React.MouseEvent<HTMLAnchorElement>): void => {
    evt.preventDefault()
    localStorage.clear()
    window.location.href = '/login'
  }

  const renderAdmin = (): JSX.Element => {
    const isAdmin = !!grants && grants.access === 'admin'
    const activePage = location.pathname.split('/')[1]

    if (!isAdmin) {
      return <React.Fragment />
    }

    return (
      <React.Fragment>
        <h6>Admin</h6>
        <Link to='/admin/products' className={
            activePage === 'admin'
            ? styles.active
            : ''
          }>
            <IoIosBuild size={ICON_SIZE} /> Edit Products
          </Link>
      </React.Fragment>
    )
  }

  const activePage = location.pathname.split('/')[1]

  return (
    <div className={styles.nav}>
      <div className={styles.logo}></div>
      <h4>Deletype</h4>
      <h5>Purchasing Portal</h5>

      <div className={styles.middle}>
        <h6>Inventory</h6>
        <Link to='/' className={
          activePage === ''
          ? styles.active
          : ''
        }>
          <IoIosList size={ICON_SIZE} /> Browse
        </Link>

        <Link to='/request' className={
          activePage === 'request'
          ? styles.active
          : ''
        }>
          <IoIosPricetags size={ICON_SIZE} /> Request
        </Link>

        {renderAdmin()}

        <h6>Account</h6>
        <a href="#logout" onClick={signOut}>
          <IoIosExit size={ICON_SIZE} /> Sign Out
        </a>
      </div>

      <div className={styles.bottom}>
        <Link to='/cart' className={
          activePage === 'cart'
          ? styles.active + ' ' + styles.cart
          : styles.cart
        }>
          <div className={styles.text}>
            <IoIosClipboard size={20} />
            <span>Quote</span>
          </div>
          <div className={styles.quantity}>
            {CartStore.numItems}
          </div>
        </Link>
      </div>
    </div>
  )
}

export default observer(Nav)
