import products from './products'
import product from './product'
import cart from './cart'
import request from './request'
import access_code from './access_code'
import login from './login'
import thanks from './thanks'

// admin components
import admin_edit_product from './admin/edit_product'
import admin_list_products from './admin/list_products'
import admin_edit_item from './admin/edit_item'

const components: any[] = [
  products,
  product,
  cart,
  request,
  access_code,
  login,
  thanks,
  admin_edit_product,
  admin_list_products,
  admin_edit_item
]

const routes: any[] = []

for (const component of components) {
  if (component.routePaths) {
    // This component wants to be mounted at multiple locations
    for (const route of component.routePaths) {
      routes.push({
        path: route.path,
        exact: route.exact || false,
        component
      })
    }
  } else {
    routes.push({
      path: component.routePath,
      exact: component.routeExact,
      component
    })
  }
}

export default routes
