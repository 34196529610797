import React from 'react'
import Shell from '../../components/layout/Shell'
import getParam from '../../utils/getParam'
import { getApi, postApi } from '../../modules/api'
import styles from '../../styles/admin/edit_product.module.scss'
import Header from '../../components/layout/Header'
import ImageBox from '../../components/misc/ImageBox'
import stateThenProps from '../../utils/stateThenProps'
import _ from 'lodash'
import NProgress from 'nprogress'
import { addToast } from '../../utils/toasts';
import history from '../../modules/history';

interface Props {
  item: dtype.ExtendedItem
  product: dtype.Product
}

interface State {
  item: dtype.ExtendedItem
  loading: boolean
}

const BLANK_ITEM: dtype.ExtendedItem = {
  id: '_new',
  name: '',
  description: '',
  price: '',
  product_id: ''
}

export default class EditItemPage extends React.Component<Props> {
  public static routePath: string[] = [
    '/admin/item/edit/:itemId',
    '/admin/item/new/:productId'
  ]

  state: State = {
    item: { ...BLANK_ITEM },
    loading: false
  }

  public static async preloadProps(match: any): Promise<Props> {
    const productId = getParam({ match }, 'productId')
    const itemId = getParam({ match }, 'itemId')

    const creatingItem = productId !== null

    if (!creatingItem) {
      // We are not creating a new item, so load the existing item details
      const { items } = await getApi('/GetItems', { ids: itemId, extended: true })
      const [ item ] = items.filter((item: any) => item.id === Number(itemId))

      const { product } = await getApi('/GetProduct', { id: item.product_id })

      return {
        item,
        product
      }
    }

    // We are creating a new item, so only load the product details
    const { product } = await getApi('/GetProduct', { id: productId })

    return {
      item: { ...BLANK_ITEM },
      product
    }
  }

  public componentDidMount(): void {
    const { item } = this.props
    this.setState({ item: { ...item } })
  }

  public render(): JSX.Element {
    const { product } = this.props
    const { item } = stateThenProps(this.state, this.props)
    const { image_url } = product
    const { id } = item
    
    return (
      <Shell>
        <div className={styles.shell}>
          <div className={styles.condense}>
            <Header subtitle='Product Metadata' className={styles.header} />
            <div className={styles.metadata}>
              <div className={styles.left}>
                <ImageBox
                  url={image_url}
                  className={styles.image}
                />
              </div>
              <div className={styles.right}>
                {this.renderForm()}
              </div>
            </div>
            {
              id !== '_new'
              ? <div className={styles.actions}>
                  <button
                    className={`${styles.button} ${styles.danger}`}
                    onClick={this.remove.bind(this)}
                  >Delete Item</button>
                </div>
              : ''
            }
          </div>
        </div>
      </Shell>
    )
  }

  private renderForm(): JSX.Element {
    const { loading } = this.state
    const { item, product } = stateThenProps(this.state, this.props)
    const { id } = item

    const cannotModify = _.isEqual(item, this.props.item)
    const isEnabled = (
      !loading &&
      !cannotModify &&
      item.name !== '' &&
      item.description !== '' &&
      item.price.length > 1
    )

    const buttonText = (): string => {
      if (loading) return 'Hold on...'
      if (id === '_new') return 'Create'
      return 'Update'
    }

    const productName = (): string => [
      product.make,
      product.model,
      product.variant
    ].join(' ')

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <div className={styles.group}>
          <input
            type='text'
            placeholder={productName()}
            value={item.name}
            onChange={this.onChange('name').bind(this)}
          />
        </div>

        <div className={styles.group}>
          <textarea
            placeholder='Details'
            value={item.description}
            onChange={this.onChange('description').bind(this)}
            rows={3}
          />
        </div>

        <div className={styles.group}>
          <input
            type='text'
            placeholder='Price'
            value={item.price}
            onChange={this.onChange('price').bind(this)}
          />
        </div>

        <button type='submit' disabled={!isEnabled}>
          {buttonText()}
        </button>
      </form>
    )
  }

  private onChange(key: string): any {
    return (evt: React.ChangeEvent<HTMLInputElement>) => {
      const item = { ...this.state.item }
      item[key] = evt.target.value
      this.setState({ item })
    }
  }

  private handleSubmit(evt: React.FormEvent) {
    evt.preventDefault()

    this.setState({ loading: true })
    NProgress.start()

    const { item, product } = this.props
    const values = { ...this.state.item }
    
    if (item.id === BLANK_ITEM.id) {
      return this.create(values, product.id)
    }

    return this.update(values)
  }

  private async create(
    values: dtype.ExtendedItem,
    productId: string|number
  ): Promise<void> {
    const done = () => {
      NProgress.done()
      this.setState({ loading: false })
    }

    values.product_id = productId

    try {
      const data = await postApi('/CreateItem', values)
      history.replace(`/admin/item/edit/${data.id}`)
    } catch (e) {
      addToast(`Hmm, that didn't work. Please check your data and try again.`, {
        appearance: 'error'
      })

      return done()
    }
  }

  private async update(values: dtype.ExtendedItem): Promise<void> {
    const done = () => {
      NProgress.done()
      this.setState({ loading: false })
    }

    try {
      await postApi('/UpdateItem', values)
    } catch (e) {
      addToast(`Hmm, that didn't work. Please check your data and try again.`, {
        appearance: 'error'
      })
      return done()
    }

    addToast('Item updated!', {
      appearance: 'success'
    })
    done()
  }

  private async remove(): Promise<void> {
    const { product } = this.props
    const { item } = stateThenProps(this.state, this.props)

    const confirm = window.confirm('Are you sure you want to delete this item?')

    if (confirm === false) {
      return
    }

    NProgress.start()

    try {
      await postApi('/RemoveItem', { id: item.id })
    } catch (e) {
      NProgress.done()

      addToast(`Hmm, that didn't work. Try again in a bit.`,
        { appearance: 'error' }
      )

      return
    }

    addToast('Item removed!', { appearance: 'success' })
    history.replace(`/admin/product/edit/${product.id}`)
  }
}
