import React, { useState } from 'react'
import Shell from '../components/layout/Shell'
import Header from '../components/layout/Header'
import styles from '../styles/cart.module.scss'
import CartStore, { CartItem } from '../stores/Cart'
import { getApi, postApi } from '../modules/api'
import NProgress from 'nprogress'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import Cart from '../stores/Cart'
import { addToast } from '../utils/toasts'
import { keyBy } from 'lodash'
import history from '../modules/history'

interface State {
  focusedItem: string|null
  actionFocused: boolean
  interimValue: string|null
  loading: boolean
  error: boolean
  done: boolean
}

interface RenderItem {
  key: string
  title: string
  subtitle?: string
  quantity: string
  link?: string
  image?: string
}

interface Props {
  metadata: {
    [itemId: string]: dtype.Item
  }
}

const INITIAL_STATE: State = Object.freeze({
  focusedItem: null,
  actionFocused: false,
  interimValue: null,
  loading: false,
  error: false,
  done: false
})

const CartPage: dtype.RoutableFC<Props> = props => {
  const [ state, setState ] = useState(INITIAL_STATE)
  
  const request = async (): Promise<void> => {
    NProgress.start()
    setState({ ...state, loading: true, error: false })

    try {
      await postApi('/RequestQuote', {
        cart: Cart.items
      })
    } catch (e) {
      NProgress.done()

      setState({
        ...state,
        loading: false,
        error: true
      })

      return
    }
    
    // We are done, clear the user's cart.
    Cart.reset()
    history.push('/thanks')
  }

  const renderItem = (renderItem: RenderItem): JSX.Element => {
    const {
      key,
      title,
      subtitle,
      quantity,
      link = null,
      image = null
    } = renderItem

    const {
      focusedItem,
      interimValue,
      actionFocused,
      loading
    } = state

    const renderImage = () => {
      if (image === null) {
        return <React.Fragment />
      }

      const imgStyles = {
        backgroundImage: `url(${image})`
      }

      return (
        <div className={styles.left}>
          <div className={styles.image}>
            <div style={imgStyles} />
          </div>
        </div>
      )
    }

    const renderCenter = () => {
      return (
        <div className={styles.center}>
          {
            link !== null
            ? <Link to={link}>{title}</Link>
            : <h3>{title}</h3>
          }
          {
            subtitle
            ? <span>{subtitle}</span>
            : ''
          }
        </div>
      )
    }

    const focused = () => {
      setState({
        ...state,
        interimValue: String(quantity),
        focusedItem: key
      })
    }

    const blurred = () => {
      if (actionFocused) return
      setState({ ...state, focusedItem: null })
    }

    const changed = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, interimValue: event.target.value })
    }

    const updateFocused = () => setState({ ...state, actionFocused: true })
    const updateBlurred = () => setState({ ...state, actionFocused: false })

    const update = (e?: any) => {
      if (e && e.preventDefault) {
        e.preventDefault()
      }

      // If the input is available, blur it
      /**
      const inputRef = this.refs[`input-${key}`] as any
      if (inputRef) inputRef.blur()
      */

      CartStore.set(
        key,
        Number(interimValue)
      )

      setState({ ...INITIAL_STATE })
    }

    const remove = () => {
      Cart.remove(key)
      setState({ ...INITIAL_STATE })
    }

    const isFocused = focusedItem === key

    return (
      <div className={styles.item} key={key}>
        {renderImage()}
        {renderCenter()}
        <form onSubmit={update} className={styles.actions}>
          <input type='text'
            pattern='\d*'
            value={
              interimValue !== null && isFocused
              ? interimValue
              : quantity
            }
            onFocus={focused}
            onBlur={blurred}
            onChange={changed}
            onSubmit={update}
            disabled={loading}
            className='w-12'
          />
          {
            isFocused
            ? <button
                onClick={update}
                onMouseEnter={updateFocused}
                onMouseLeave={updateBlurred}
                disabled={loading}
              >Update</button>
            : <button onClick={remove} disabled={loading}>Remove</button>
          }
        </form>
      </div>
    )
  }

  const renderInventoryItem = (item: CartItem): JSX.Element => {
    const key = item.id

    const { metadata } = props
    const itemData = metadata[key]

    const { quantity } = item
    const { image_url, product_id } = itemData

    return renderItem({
      key,
      title: itemData.name,
      subtitle: itemData.description,
      quantity: String(quantity),
      link: `/product/${product_id}?selected=${item.id}`,
      image: image_url
    })
  }

  const renderDone = (): JSX.Element => {
    return (
      <Shell>
        <div className={styles.shell}>
          <Header title='Pending Quote' subtitle='Ordering' />
          <div className={styles.empty}>
            <h4>Thanks! Your request has been submitted.</h4>
          </div>
        </div>
      </Shell>
    )
  }

  const renderEmpty = (): JSX.Element => {
    return (
      <div className={styles.empty}>
        <h4>You haven't added any items yet!</h4>
      </div>
    )
  }

  const renderInventoryInCart = (inventory: CartItem[]): JSX.Element => {
    if (inventory.length < 1) {
      return <React.Fragment />
    }

    return (
      <div className={styles.cart}>
        {inventory.map(renderInventoryItem.bind(this))}
      </div>
    )
  }

  const render = (): JSX.Element => {
    const { loading, done, error } = state
    const { items } = CartStore

    if (done) {
      return renderDone()
    }

    const renderSummary = () => {
      if (items.length < 1) {
        return <React.Fragment />
      }

      return (
        <div className={styles.summary}>
          <h4>{CartStore.numItems} {CartStore.numItems === 1 ? 'item' : 'items'}</h4>
          <button
            disabled={loading}
            onClick={request}
          >
            {
              loading
              ? 'Requesting...'
              : 'Request Quote'
            }
          </button>
        </div>
      )
    }

    const renderError = () => {
      if (error === false) {
        return <React.Fragment />
      }

      return (
        <p className={styles.error}>
          Hmm, something went wrong. Please try again in a few minutes.
        </p>
      )
    }

    const _renderEmpty = () => items.length < 1
      ? renderEmpty()
      : <React.Fragment />

    return (
      <Shell>
        <React.Fragment>
          <div className={styles.shell}>
            <Header title='Pending Quote' subtitle='Ordering' />
            {renderError()}
            {renderInventoryInCart(items)}
            {renderSummary()}
          </div>
          {_renderEmpty()}
        </React.Fragment>
      </Shell>
    )
  }

  return render()
}

CartPage.preloadProps = async () => {
  /**
   * We will re-assign this later if we need to remove items that are no longer
   * available
   */
  let items = CartStore.items 
  const itemIds: string[] = items.map(item => item.id)

  if (itemIds.length === 0) {
    return {
      items: [],
      metadata: {}
    }
  }

  const response = await getApi('/GetItems', {
    ids: itemIds.join(',')
  })

  const metadata: Props['metadata'] = keyBy(response.items, 'id')
  const itemsToBeRemoved: any[] = []

  for (const itemId of itemIds) {
    if (!metadata.hasOwnProperty(itemId)) {
      itemsToBeRemoved.push(itemId)
    }
  }

  if (itemsToBeRemoved.length > 0) {
    for (const itemId of itemsToBeRemoved) {
      CartStore.remove(itemId)
    }

    addToast([
      'Some items in your cart are no longer available, and have been',
      'removed.'
    ].join(' '), { appearance: 'warning' })
  }

  return {
    metadata
  }
}

CartPage.routePaths = [{ path: '/cart', exact: true }]

export default observer(CartPage)
