/* eslint-disable no-restricted-globals */
import React from 'react'
import history from './modules/history'
import {
  Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom'
import routes from './pages/_routes'
import NProgress from 'nprogress'
import { BarLoader } from 'react-spinners'
import 'normalize.css'
import 'nprogress/nprogress.css'
import './index.css'
import Toasts from './components/dummies/Toasts'

NProgress.configure({ trickleSpeed: 400 })

interface State {
  didPreload: boolean
  injectedProps: any
}

class App extends React.Component<any, State> {
  constructor (props: any) {
    super(props)

    this.state = {
      didPreload: false,
      injectedProps: {}
    }
  }

  async componentDidMount(): Promise<void> {
    await this.handleInitialLoad()
  }

  public render(): JSX.Element {
    const { didPreload } = this.state

    if (!didPreload) {
      return this.renderLoading()
    }

    return this.renderApp()
  }

  private renderLoading = (): JSX.Element => {
    return (
      <div className='app-loading'>
        <div className='logo'>
          <div />
        </div>
        <BarLoader color={'#000000'} />
      </div>
    )
  }

  private renderApp = (): JSX.Element => {
    return (
      <React.Fragment>
        <Router history={history.browserHistory}>
          <Switch>
            {routes.map(this.renderRoute)}
          </Switch>
        </Router>
        <Toasts />
      </React.Fragment>
    )
  }

  private renderRoute = (route: any): JSX.Element => {  
    return (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        render={() => {
          const { component } = route
          const injectedProps = history.getInjectedProps(component.routePath)
          const WithRouterComponent = withRouter(component)

          return <WithRouterComponent {...injectedProps} />}
        }
      />
    )
  }

  private handleInitialLoad = async (): Promise<void> => {
    // We are loading for the first time
    const locationShim = {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
      state: {}
    }

    NProgress.start()
    await history.preloadRoute(locationShim as any)
    NProgress.done()

    this.setState({ didPreload: true })
  }
}

export default App
