import { action, computed, makeObservable, observable } from 'mobx'
import murmur from 'murmurhash-js'

export interface CartItem {
  id: string
  quantity: number
}

interface CartContents {
  [key: string]: number
}

interface CustomItemMetadata {
  name: string
  details: CustomItemDetails
}

interface CustomItemDetails {
  [key: string]: string
}

interface CustomItemMetadataContainer {
  [itemId: string]: CustomItemMetadata
}

class Cart {
  contents: CartContents = {}
  itemOrder: string[] = []
  customItemMetadata: CustomItemMetadataContainer = {}

  constructor() {
    makeObservable(this, {
      contents: observable,
      itemOrder: observable,
      numItems: computed,
      items: computed,
      add: action,
      remove: action,
      set: action,
      addCustom: action
    })

    this.handleFormatChanges()
    this.unserialize()
  }

  get numItems(): number {
    const keys = Object.keys(this.contents)
    let quantity: number = 0

    for (const key of keys) {
      quantity += this.contents[key]
    }

    return quantity
  }

  get items(): CartItem[] {
    const items: CartItem[] = this.itemOrder.map(id => {
      const quantity = this.contents[id]

      return { id, quantity }
    })

    return items
  }

  public add = (
    itemId: string,
    quantity: number
  ): void => {
    const key = itemId
    const currentQuantity = this.currentQuantity(key)
    
    this.set(key, currentQuantity + quantity)
  }

  public remove = (key: string): void => {
    this.set(key, 0)
  }

  public set = (
    key: string,
    quantity: number
  ): void => {
    const saneQuantity = Math.round(Math.max(0, quantity))

    if (isNaN(saneQuantity)) {
      return
    }

    if (saneQuantity < 1) {
      // If there is less than one item, remove it all together
      if (this.contents.hasOwnProperty(key)) {
        delete this.contents[key]
      }

      if (this.customItemMetadata.hasOwnProperty(key)) {
        delete this.customItemMetadata[key]
      }

      // Remove the item from the order array
      const orderIndex = this.itemOrder.indexOf(key)

      if (orderIndex !== -1) {
        this.itemOrder.splice(orderIndex, 1)
      }
    } else {
      // Otherwise, set the new quantity
      this.contents[key] = saneQuantity

      // We need to maintain the order of items being added
      if (!this.itemOrder.includes(key)) {
        this.itemOrder.push(key)
      }
    }

    this.serialize()
  }

  public addCustom = (
    name: string,
    details: any,
    quantity: number
  ): void => {
    /**
     * If the user enters the exact same details more than once, then just
     * increase the amount (this is why we're using a hash)
     */
    const itemHash = murmur(`${name}:${JSON.stringify(details)}`.toLowerCase())
    const key = `custom.${String(itemHash)}`
    const currentQuantity = this.currentQuantity(key)

    this.customItemMetadata[key] = {
      name,
      details
    }
    
    this.set(key, currentQuantity + quantity)
  }

  public key = (productId: string, variantId: string): string => {
    return `${productId}:${variantId}`
  }

  private currentQuantity = (key: string): number => {
    let currentQuantity: number = 0

    if (this.contents.hasOwnProperty(key)) {
      // This item is already in the cart
      currentQuantity = this.contents[key]
    }

    // Quantity can never be lower than zero
    currentQuantity = Math.max(0, currentQuantity)

    // Quantity should always be a whole integer
    currentQuantity = Math.round(currentQuantity)

    return currentQuantity
  }

  public reset = (): void => {
    const existingAuth = localStorage.getItem('access_code')

    localStorage.clear()
    localStorage.setItem('last_local_reset', String(Date.now()))

    if (existingAuth) {
      localStorage.setItem('access_code', existingAuth)
    }

    this.contents = {}
    this.itemOrder = []
    this.customItemMetadata = {}

    this.serialize()
    window.location.reload()
  }

  private serialize = (): void => {
    localStorage.setItem('cart', JSON.stringify({
      contents: this.contents,
      itemOrder: this.itemOrder,
      customItemMetadata: this.customItemMetadata
    }))
  }

  private unserialize = (): void => {
    try {
      const json = localStorage.getItem('cart') || ''
      const { contents, itemOrder, customItemMetadata } = JSON.parse(json)

      this.contents = contents || {}
      this.itemOrder = itemOrder || []
      this.customItemMetadata = customItemMetadata || {}
    } catch (e) {
      this.reset()
    }

    /**
     * If we have custom metadata, let's ensure that only items for the cart
     * are retained
     */
    for (const key of Object.keys(this.customItemMetadata)) {
      if (!this.contents.hasOwnProperty(key)) {
        delete this.customItemMetadata[key]
      }
    }
  }

  private handleFormatChanges = (): void => {
    const lastReset = 1623190098695
    const lastLocalReset = parseInt(
      localStorage.getItem('last_local_reset') || ''
    )

    if (isNaN(lastLocalReset) || lastLocalReset < lastReset) {
      // We need to reset since the format has changed
      console.log('resetting cart due to format change')
      this.reset()
    }
  }
}

export default new Cart()
