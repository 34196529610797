import React, { ReactNode } from 'react'
import styles from './AuthShell.module.scss'

interface Props {
  children: ReactNode
}

const AuthShell: React.FC<Props> = props => {
  const { children } = props

  return (
    <div className={styles.app}>
      <div className={styles.page}>
        <div className={styles.logo}>
          <div />
          <h4>Deletype</h4>
          <h5>Purchasing Portal</h5>
        </div>
        <div className={styles.children}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthShell
