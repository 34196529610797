import Header from '../components/layout/Header'
import Shell from '../components/layout/Shell'
import styles from '../styles/cart.module.scss'

const ThanksPage: dtype.RoutableFC<{}> = props => {
  return (
    <Shell>
      <div className={styles.shell}>
        <Header title='Pending Quote' subtitle='Ordering' />
        <div className={styles.empty}>
          <h4>Thanks! Your request has been submitted.</h4>
        </div>
      </div>
    </Shell>
  )
}

ThanksPage.routePaths = [
  { path: '/thanks', exact: true }
]

export default ThanksPage
