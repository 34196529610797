import React, { InputHTMLAttributes } from 'react'
import classNames from 'classnames'
import { useField } from 'formik'
import { FaExclamationCircle } from 'react-icons/fa'
import { omit } from 'lodash'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  useInlineStyle?: boolean
  inputClassNames?: string
}

const TextInput: React.FC<Props> = props => {
  const {
    useInlineStyle = false,
    inputClassNames = '',
    disabled = false
  } = props

  const withoutCustomProps = omit(props, [
    'useInlineStyle',
    'inputClassNames'
  ])

  const [ field, meta ] = useField(props)
  const hasError = meta.touched && meta.error
  const providedClasses: string[] = typeof props.className === 'string'
    ? props.className.split(' ')
    : []

  const containerClasses: string = classNames(
    'block relative',
    providedClasses.join(' '),
    {
      'w-full': providedClasses.filter(i => i.indexOf('w-') === 0).length === 0
    }
  )

  const styles = {
    normal: classNames({
      'shadow-sm border-gray-300': !useInlineStyle,
      'focus:ring-blue-500 focus:border-blue-500': true
    }),
    errored: classNames({
      'shadow-sm border-red-300': !useInlineStyle,
      'text-red-900 placeholder-red-300': true,
      'focus:outline-none focus:ring-red-500 focus:border-red-500 pr-10': true
    }),
    disabled: classNames({
      'border-gray-200 placeholder-gray-300 cursor-not-allowed': !useInlineStyle
    })
  }

  const inputClasses: string = classNames(
    inputClassNames,
    'block w-full text-sm',
    {
      'rounded-md': !useInlineStyle,
      'border-0 focus:ring-2': useInlineStyle,
      [styles.normal]: !hasError && !disabled,
      [styles.errored]: hasError && !disabled,
      [styles.disabled]: disabled
    }
  )

  const errorIcon: JSX.Element = (
    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
      <FaExclamationCircle className='text-red-500' />
    </div>
  )

  return (
    <div className={containerClasses}>
      <input
        {...field}
        {...withoutCustomProps}
        className={inputClasses}
        type='text'
      />
      { hasError && !disabled ? errorIcon : null }
    </div>
  )
}

export default TextInput
