let toastManager: any = null

interface ToastOptions {
  appearance: 'success'|'info'|'warning'|'error'
  autoDismiss: boolean
  pauseOnHover: boolean
}

const DEFAULT_OPTIONS: ToastOptions = {
  appearance: 'success',
  autoDismiss: true,
  pauseOnHover: true
}

export function stashToastManager(manager: any): void {
  toastManager = manager
}

export function addToast(
  content: string,
  options: Partial<ToastOptions> = {}
): void {
  if (toastManager === null) {
    return
  }
  
  const defaults = {
    ...DEFAULT_OPTIONS,
    ...options
  }

  toastManager.add(content, defaults)
}