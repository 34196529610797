import { createBrowserHistory, Location } from 'history'
import { matchPath } from 'react-router-dom'
import routes from '../pages/_routes'
import NProgress from 'nprogress'

class History {
  private INJECTED_PROPS: any = {}

  public blockHandler = (nextLocation: Location): any => {
    return `_preload:${JSON.stringify(nextLocation)}`
  }

  public getComponentsForRoute = (path: string): any => {
    const preloadComponents = []
  
    for (const route of routes) {
      const match = matchPath(path, route)
  
      if (match && route.component.preloadProps) {
        preloadComponents.push({ component: route.component, match })
      }
    }
  
    return preloadComponents
  }

  public preloadRoute = async (nextLocation: Location): Promise<void> => {
    const preloadComponents = this.getComponentsForRoute(nextLocation.pathname)
  
    for (const { component, match } of preloadComponents) {
      const injectedProps = await component.preloadProps(match, nextLocation)
      this.setInjectedProps(component.routePath, injectedProps)
    }
  }

  public getInjectedProps = (key: string): any => {
    if (!this.INJECTED_PROPS.hasOwnProperty(key)) {
      return {}
    }
  
    const returnedProps = { ...this.INJECTED_PROPS[key] }
    delete this.INJECTED_PROPS[key]
  
    return returnedProps
  }

  public setInjectedProps = (key: string, props: any): void => {
    this.INJECTED_PROPS[key] = props
  }

  public getUserConfirmation = async (messageOrAction: any, callback: any): Promise<void> => {    
    if (messageOrAction.indexOf('_preload:') !== 0) {
      NProgress.done(true)
      return callback(true)
    }

    const nextLocation: Location = JSON.parse(
      messageOrAction.replace('_preload:', '')
    )

    NProgress.start()
    await this.preloadRoute(nextLocation)
    NProgress.done()

    return callback(true)
  }

  public browserHistory = createBrowserHistory({
    getUserConfirmation: this.getUserConfirmation.bind(this)
  })

  public push(path: string): void {
    this.browserHistory.push(path)
  }

  public replace(path: string): void {
    this.browserHistory.replace(path)
  }
}

const history = new History()

history
  .browserHistory
  .block(history.blockHandler)

export default history
